<script setup lang="ts">
import type { ConcreteComponent } from "vue"

import {
  PopoverRoot,
  PopoverTrigger,
  PopoverContent,
  PopoverPortal,
  type PopoverContentProps,
} from "reka-ui"

defineOptions({
  inheritAttrs: false,
})

export type ItemType = {
  id: string
  icon: string | ConcreteComponent
  label: string
  show?: boolean
  type?: string
  testId?: string
}
const { sideOffset = 4 } = defineProps<{
  items: ItemType[]
  testId?: string
  align?: PopoverContentProps["align"]
  side?: PopoverContentProps["side"]
  sideOffset?: PopoverContentProps["sideOffset"]
}>()

const emit = defineEmits<{
  (e: "open"): void
  (e: "choose", item: ItemType): void
}>()

function handleUpdateOpen(isOpen: boolean) {
  if (isOpen) {
    emit("open")
  }
}

const open = ref(false)

function handleSelectOption(item: ItemType) {
  open.value = false
  emit("choose", item)
}
</script>

<template>
  <div :class="$attrs.class" class="relative flex items-center">
    <PopoverRoot v-model:open="open" @update:open="handleUpdateOpen">
      <PopoverTrigger class="w-full">
        <div :class="$attrs.class" :data-testid="testId">
          <slot />
        </div>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent :side :side-offset :align class="PopoverContent z-50">
          <div
            data-testid="dropdownmenu_options"
            :class="[$attrs.class]"
            class="flex flex-col overflow-hidden rounded-lg border border-slate-300 bg-white transition-all"
          >
            <div v-for="item in items" :key="item.id">
              <button
                class="flex w-full cursor-pointer items-center justify-start py-2 pr-6 pl-2 text-sm font-medium whitespace-nowrap text-slate-700 transition duration-300 select-none hover:bg-slate-50 hover:text-slate-800"
                :data-testid="item?.testId"
                @click="handleSelectOption(item)"
              >
                <component :is="item.icon" class="mr-2 size-4 shrink-0" />
                {{ item.label }}
              </button>
              <div
                v-if="item.show && item.type === 'separator'"
                class="my-[6px] border-t border-slate-200"
              ></div>
            </div>
          </div>
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  </div>
</template>

<style scoped>
:deep(.PopoverContent) {
  min-width: var(--reka-popover-trigger-width);
  animation: scaleIn 0.2s ease-out;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
